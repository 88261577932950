import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "C:/Users/felix/OneDrive/Documents/Bertram.Solutions/Website.TTcom/GatsbyJS-2022/src/components/mdx/component.js";
import { GenericPage } from "../../../../../components/mdx/page";
import { Breadcrumbs } from "../../../../../components/widgets/breadcrumbs";
import { Aside } from "../../../../../components/widgets/aside";
import { Border } from "../../../../../components/widgets/container";
import { ButtonPrimary, ButtonSecondary } from "../../../../../components/widgets/button";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = GenericPage;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <h1>{props.pageContext.frontmatter.title}</h1>
    <Breadcrumbs to="/company/newsletter/" text="Newsletter Archive" mdxType="Breadcrumbs" /> 
    <p>{`Hello TuringTrader Member,`}</p>
    <p>{`Wow, it's March already! If you are paying close attention, you might have noticed that this newsletter comes a week later than usual. That is because, behind the scenes, we are super busy finalizing some exciting updates.`}</p>
    <p><a parentName="p" {...{
        "href": "/dashboard/market-vane/"
      }}><span parentName="a" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "960px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABm0lEQVQoz32TCY7bMAxFc//LFe0BGhRoZ5LYlrVYovZXyKmzFGgJyKK4fNH81GlSii9fv3E+nxERWmuUUmit4rwQYmZI73188CGird/3EVNKpda658QYOWnruE3Lfqi1cUgHvCS2kJ623lEmIDGTcr1f8uLbAUcVMVfepdMb5FyZ1o1S6yNp1tsOGFP9U0B/+FLKnDYR5k2h/BUtC5Nf8dWjZGXaNG0Al3vS0K3PO4QkodR7O1Jp5JKIUTjVImzbhLGGFM2uh7AxG8Wn/mRLK9pdacVQi0PE0KsmJYMPCrplUVfW9UrJkVMpgvcO7z1Q6S1QsqJXD73i44WL/snHcuHH9B27zdASrWZmpZn0DRsUs1mwzg3A0Vyorb91sbXBoqe1wodWXFbDr8myBEvsEWnCKg4tjtwLPgs+BE4554OmvbHP9bRpK1gnGCc7UY8xeueRlNKosPA/GYnWR9YBVuq/4+h3wBDCPtB/74cexhQojdKOEOThO/wpRoyx3G4TRut7heO3xxr6uOU4D33YBmEDYLyIV/9rzhH7G7wcWxXICD3sAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "mv monthly",
            "title": "mv monthly",
            "src": "/static/442db6bc783968d50a248d129ba54d5c/d9199/mv-monthly.png",
            "srcSet": ["/static/442db6bc783968d50a248d129ba54d5c/5a46d/mv-monthly.png 300w", "/static/442db6bc783968d50a248d129ba54d5c/0a47e/mv-monthly.png 600w", "/static/442db6bc783968d50a248d129ba54d5c/d9199/mv-monthly.png 960w"],
            "sizes": "(max-width: 960px) 100vw, 960px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span></a></p>
    <p>{`Before we go there, let's have a look at `}<a parentName="p" {...{
        "href": "/dashboard/market-vane/"
      }}>{`Market Vane`}</a>{`. As you noticed, the indicator is bearish for March. With stock vs. bond performance clearly positive and the economic momentum negative, the stock market momentum is the tiebreaker. At the end of February, it was hovering around a neutral level, dipping into negative territory to turn the overall indicator bearish. Since then, momentum has turned more negative, making it less of a decision on a knife's edge.`}</p>
    <p>{`Last year was characterized by a complete revamp of the website and its workflows. This year, we'll make many changes to the technical underpinnings and the backend. With the `}<a parentName="p" {...{
        "href": "https://us20.campaign-archive.com/?u=c6214e7ef367ae327105288e4&id=72e5b91c26"
      }}>{`December newsletter`}</a>{`, we introduced `}<a parentName="p" {...{
        "href": "/portfolios/keller-bold-asset-allocation/"
      }}>{`Keller's Bold Asset Allocation`}</a>{`, the first outing of our new v2 backtesting engine.`}</p>
    <p>{`We have made tremendous progress since, and we are putting the finishing touches on `}<a parentName="p" {...{
        "href": "https://www.turingtrader.org/blog/2023/03-sixteen/"
      }}>{`version 16 of our TuringTrader backtesting engine`}</a>{`. This new engine greatly simplifies writing code. It is specifically designed with meta portfolios in mind, just like our `}<a parentName="p" {...{
        "href": "/portfolios/?tag=meta"
      }}>{`All-Stars family of portfolios`}</a>{`. With the new engine, the accuracy of backtests will improve, especially in fast-moving markets. This is particularly relevant for leveraged strategies, like our `}<a parentName="p" {...{
        "href": "/portfolios/tt-mach-2/"
      }}>{`Mach-2`}</a>{` or `}<a parentName="p" {...{
        "href": "/portfolios/tt-mach-3/"
      }}>{`Mach-3`}</a>{`. But the new engine will also simplify coding, which allows us to research and experiment more.`}</p>
    <p>{`Over the coming months, we will slowly start migrating strategies from the v1 engine to the v2 engine. We will begin with porting the `}<a parentName="p" {...{
        "href": "/portfolios/?tag=basic"
      }}>{`Basic strategies`}</a>{` and the `}<a parentName="p" {...{
        "href": "/portfolios/?tag=buy-and-hold"
      }}>{`lazy portfolios`}</a>{`. Even though this sounds counter-intuitive, the latter will also include the 'outer shell' of the All-Stars portfolios, putting us in the position to update their component strategies individually. We expect this process to be seamless. However, you might need to slightly adjust the account values of the portfolios on your dashboard.`}</p>
    <p><a parentName="p" {...{
        "href": "/portfolios/keller-hybrid-asset-allocation/"
      }}><span parentName="a" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "972px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "54.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACk0lEQVQoz22SPWwURxSAV6ZAEHc0NJRpUibCIoB9loiSdOloElEewVEa/lKhNBREchEnkRCxERgbMAg3JAjbGAQyPzaOgVjCwpx/MDGc7i57u7Mzs7Ozc+cv2rUjGkb6NE9v5n2aNzNeYi3/Y2yCsw2qpsJM8BgVSZ69rLC06iOlwA8C/CBExSan0UhJU0dpJWTlrSB1KZ5JEjJy4UasjUFolRdFKs7RsSF1FmgAa2SjXFXsPzHO1s/6+eG3ScC9E8aZKDY4l9JsOqC5wXpxFld9zfX7y3RfmuX4mQfs7LrK5s5+Wvad4ejpe/keL2szE2b2LFGra1YrEef+KPF992OO/DrFj30zfNs9wUffDNP6eT+bOvto6ThL675Btn85zOY9Qxzvmc5P71nrcM4xt1TnWM8Tdnw1zLYvruDtOYfX9jteWy/ertN4bWfZtHuQLe1DtBau5GztGOKDzst4n1zg8M/TeTfeL9en+GnwCftP3ObDry+xq+san353jULXDToPjVAo3qJQHKNwcIyO4ih7iyPsLd6kPZ9HKBwc5eMDf9I98Pf6Hb6phcxW5nlVf4tJYpTRSB3jC4nQEmkkxmpCJfGjCCE1YaQJpCSMFH4kEFGMiqL8Yb3LU5OcujjNwM0XDNx5yvmJh1yYmOR5+RVDE8/oHf2Lk8O3ufroKa/9CnfnXjA+N8vDhXnuL88y87rE1FIJGYXrwjdBjQcvF5iv/cN8ZZVyXVCuh1ib4ocxVT9muRzgC53/uVApRKzwdUBV/4tL1lhUi6yEJZSM8dbSBovmOUFSA9fEpTbHGEOaJjiX0GxY0uw3GIO1CTaxJIl5h02QUR2pFF4kAoSoE4k6Kgrfi9xAS/He9SwvwgClNf8B9L/tJC/Qrd0AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "haa",
            "title": "haa",
            "src": "/static/067eaeccc181989414e59bcb9beea1e4/7ae9c/haa.png",
            "srcSet": ["/static/067eaeccc181989414e59bcb9beea1e4/5a46d/haa.png 300w", "/static/067eaeccc181989414e59bcb9beea1e4/0a47e/haa.png 600w", "/static/067eaeccc181989414e59bcb9beea1e4/7ae9c/haa.png 972w"],
            "sizes": "(max-width: 972px) 100vw, 972px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span></a></p>
    <p>{`Of course, we didn't want to send out this newsletter without introducing something tangible. As you surely know by now, we are big fans of Wouter Keller and his work. Together with Jan Willem Keuning, he published a new strategy, the `}<a parentName="p" {...{
        "href": "/portfolios/keller-hybrid-asset-allocation/"
      }}>{`Hybrid Asset Allocation`}</a>{`. This strategy performs charmingly well, especially for running on a monthly rebalancing schedule. We feel that this is a fantastic addition to our suite of Basic portfolios and an interesting concept worth revisiting for our proprietary research.`}</p>
    <p>{`And finally, we have another live stream coming up in collaboration with my friend Evan Medeiros from The Trade Risk. On March 22, we will explain line by line `}<a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=JbfhaJm77K8"
      }}>{`how to code a simple momentum strategy`}</a>{`, run the backtest, and discuss the results. We'd love to see you there!`}</p>
    <p>{`As always, I want to thank everybody who reached out over the past month with questions, comments, and suggestions. It’s an honor to have you on our site. If you like what we do, support our growth by sharing how `}<a parentName="p" {...{
        "href": "/"
      }}>{`TuringTrader`}</a>{` adds value for you with your friends.`}</p>
    <p>{`Stay tuned for further updates,`}<br />{`
All the best`}</p>
    <p>{`Felix`}<br />{`
--`}<br />{`
Felix Bertram`}<br />{`
Founder of TuringTrader.com`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      